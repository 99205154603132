import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import TitleStyled from "../components/styles/titleStyles"
import ContactStyled from "../components/styles/ContactStyles"
import myInitObject from "../ultis/variable"
import GoogleMap from "../components/googleMaps"
import HalfImage from "../components/half/halfImage"
import SEO from "../components/seo"
import HalfContact from "../components/half/halfContact"

const IndexPage = ({ data, pageContext }) => {
  const { conactSingleton } = data.swapi
  const address = {
    lat: 52.2280491,
    lng: 21.0122099,
  }
  const googleMapsApiKey = "AIzaSyDrYh8pDYJw20JMkEQcDEA22KtoenkXYUY"
  return (
    <Layout pageContext={pageContext}>
      <SEO
        title={conactSingleton.metaTitle}
        description={conactSingleton.metaDescription}
        img={conactSingleton.metaImage ? conactSingleton.metaImage.path : ""}
        lang="pl"
      />
      <ContactStyled>
        <div className="half">
          <HalfContact form={conactSingleton.form} position="left" />
          <HalfImage
            backgroundImage={
              myInitObject.cockpitUrl + conactSingleton.form.img.path
            }
            hideMobile={false}
          />
        </div>
        <div className="container">
          <div className="contactData">
            <div className="contactMap">
              <GoogleMap
                address={address}
                googleMapsApiKey={googleMapsApiKey}
              />
            </div>
            <div className="contactAddress">
              <TitleStyled
                textAlign="left"
                linePosition="left"
                color="#000"
                lineColor="#000"
              >
                <h2
                  dangerouslySetInnerHTML={{
                    __html: conactSingleton.office.heading,
                  }}
                ></h2>
              </TitleStyled>
              <div
                dangerouslySetInnerHTML={{
                  __html: conactSingleton.office.description,
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="contactRegister">
          <div className="container">
            <TitleStyled
              textAlign="center"
              linePosition="center"
              color="#000"
              lineColor="#000"
            >
              <h2
                dangerouslySetInnerHTML={{
                  __html: conactSingleton.registrationData.heading,
                }}
              ></h2>
            </TitleStyled>
            <div
              className="contactRegister__left"
              dangerouslySetInnerHTML={{
                __html: conactSingleton.registrationData.descriptionLeft,
              }}
            ></div>
            <div
              className="contactRegister__right"
              dangerouslySetInnerHTML={{
                __html: conactSingleton.registrationData.descriptionRight,
              }}
            ></div>
          </div>
        </div>
        {/* <div className="container">
          <div className="contactCloudTechnologies">
            <div className="contactCloudTechnologies__wrapper">
              <div className="contactCloudTechnologies__image">
                <img
                  src={
                    myInitObject.cockpitUrl +
                    conactSingleton.cloudTechnologies.img.path
                  }
                  alt="cloudTechnologies"
                />
              </div>
              <div
                className="contactCloudTechnologies__description"
                dangerouslySetInnerHTML={{
                  __html: conactSingleton.cloudTechnologies.description,
                }}
              ></div>
              <div className="contactCloudTechnologies__link">
                <a href={conactSingleton.cloudTechnologies.link}>
                  {conactSingleton.cloudTechnologies.linkText}
                </a>
              </div>
            </div>
          </div>
          <div className="contactPages">
            <div className="contactPages__col">
              <div>
                <div className="contactOnAudience">
                  <div className="contactPages__img">
                    <img
                      src={
                        myInitObject.cockpitUrl +
                        conactSingleton.onAudience.img.path
                      }
                      alt="onAudience"
                    />
                  </div>
                  <div
                    className="contactPages__description"
                    dangerouslySetInnerHTML={{
                      __html: conactSingleton.onAudience.description,
                    }}
                  ></div>
                  <div className="contactPages__link">
                    <a href={conactSingleton.onAudience.link}>
                      {conactSingleton.onAudience.linkText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="contactPages__col">
              <div>
                <div className="contactAudience">
                  <div className="contactPages__img">
                    <img
                      src={
                        myInitObject.cockpitUrl +
                        conactSingleton.audienceNetwork.img.path
                      }
                      alt="audienceNetwork"
                    />
                  </div>
                  <div
                    className="contactPages__description"
                    dangerouslySetInnerHTML={{
                      __html: conactSingleton.audienceNetwork.description,
                    }}
                  ></div>
                  <div className="contactPages__link">
                    <a href={conactSingleton.audienceNetwork.link}>
                      {conactSingleton.audienceNetwork.linkText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="contactPages__col">
              <div>
                <div className="contactOAN">
                  <div className="contactPages__img">
                    <img
                      src={
                        myInitObject.cockpitUrl + conactSingleton.oan.img.path
                      }
                      alt="oan"
                    />
                  </div>
                  <div
                    className="contactPages__description"
                    dangerouslySetInnerHTML={{
                      __html: conactSingleton.oan.description,
                    }}
                  ></div>
                  <div className="contactPages__link">
                    <a href={conactSingleton.oan.link}>
                      {conactSingleton.oan.linkText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </ContactStyled>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query conactSingletonPL {
    swapi {
      conactSingleton(lang: "pl") {
        metaTitle
        metaDescription
        metaImage {
          path
        }
        audienceNetwork {
          description
          img {
            path
          }
          link
          linkText
        }
        cloudTechnologies {
          description
          link
          img {
            path
          }
          linkText
        }
        oan {
          description
          img {
            path
          }
          link
          linkText
        }
        onAudience {
          description
          img {
            path
          }
          link
          linkText
        }
        form {
          buttonText
          checkboxError
          checkboxText
          heading
          img {
            path
          }
          placeholderEmail
          placeholderMessage
          placeholderName
          placeholderPhone
          emailError
          nameError
          phoneError
          thx
        }
        office {
          description
          heading
        }
        registrationData {
          descriptionLeft
          descriptionRight
          heading
        }
      }
    }
  }
`
